import {lazy} from "react";
import {Hoc} from "../hoc/Auth";

// const SignIn = lazy(() => import("../ui/SignIn"));
// const SignUp = lazy(() => import("../ui/SignUp"));
// const VerifyCode = lazy(() => import("../ui/VerifyCode"));
// const ForgotPasswordEmail = lazy(() => import("../ui/ForgotPassword/Email"));
// const ForgotPasswordVerifyCode = lazy(() => import("../ui/ForgotPassword/VerifyCode"));
const MaintenancePage = lazy(() => import("../ui/MaintenancePage"));
const NotFoundPage = lazy(() => import("../ui/NotFoundPage"));
const ExpiredPage = lazy(() => import("../ui/ExpiredPage"));
// const ForgotCreatePassword = lazy(() => import("../ui/ForgotPassword/CreatePassword"));
const Guest = lazy(() => import("../ui/Guest"));

const EmailWr = lazy(() => import("../components/Authorization/Wrappers/EmailWr"));
const VerificationWr = lazy(() => import("../components/Authorization/Wrappers/VerificationWr"));
const ConfirmPasswordWr = lazy(() => import("../components/Authorization/Wrappers/ConfirmPasswordWr"));
const CreatePasswordWr = lazy(() => import("../components/Authorization/Wrappers/CreatePasswordWr"));
const ForgotPasswordEmailWr = lazy(() => import("../components/Authorization/Wrappers/ForgotPasswordEmailWr"));
const ForgotPasswordWr = lazy(() => import("../components/Authorization/Wrappers/ForgotPasswordWr"));
const NickNameWr = lazy(() => import("../components/Authorization/Wrappers/NickNameWr"));
const PasswordWr = lazy(() => import("../components/Authorization/Wrappers/PasswordWr"));
const TermsAndPrivacyWr = lazy(() => import("../components/Authorization/Wrappers/TermsAndPrivacyWr"));
const Dashboard = lazy(() => import("../containers/dashboard"));
const ViewWithIllustrationWr = lazy(() => import("../containers/Illustration"));
const Profile = lazy(() => import("../containers/profile"));
const Spaces = lazy(() => import("../containers/spaces"));
// const Expired = lazy(() => import("../containers/expired"));
// const NotFound = lazy(() => import("../containers/notFound"));
// const IllustrationWr = lazy(() => import("../containers/Illustration"));
const FastexComp = lazy(() => import("../components/fastexComp"));
// const Guest = lazy(() => import("../containers/guest"));
// const Maintenance = lazy(() => import("../containers/maintenance"));
const ForgotPassword = lazy(() => import("../containers/newForgot"));
const RedirectContent = lazy(() => import("../containers/redirectContent"));
const Room = lazy(() => import("../containers/room"));
const DreamPackage = lazy(() => import("../containers/dreamPackage"));
const InviteContent = lazy(() => import("../containers/inviteContent"));
const HomeSpace = lazy(() => import("../containers/homeSpace"));
const BuyNft = lazy(() => import("../containers/buyNft"));
const TwitchComp = lazy(() => import("../components/TwitchComp"));

const routes = [
    {
        path: "/",
        component: ViewWithIllustrationWr,
        title: "Login",
    },
    // {
    //     path: "/signup",
    //     component: SignUp,
    //     title: "SignUp",
    // },
    // {
    //     path: "/signup/verify_code",
    //     component: VerifyCode,
    //     title: "VerifyCode",
    // },
    // {
    //     path: "/forgot/verify",
    //     component: ForgotPasswordVerifyCode,
    //     title: "ForgotPasswordVerifyCode",
    // },
    // {
    //     path: "/forgot",
    //     component: ForgotPasswordEmail,
    //     title: "ForgotPasswordEmail",
    // },
    {
        path: "/login",
        component: EmailWr,
        title: "EmailWr",
    },
    {
        path: "/login/pass",
        component: PasswordWr,
        title: "PasswordWr",
    },
    {
        path: "/signup/pass",
        component: CreatePasswordWr,
        title: "CreatePasswordWr",
    },
    {
        path: "/signup/confirm",
        component: ConfirmPasswordWr,
        title: "ConfirmPasswordWr",
    },
    {
        path: "/signup/terms",
        component: TermsAndPrivacyWr,
        title: "TermsAndPrivacyWr",
    },
    {
        path: "/signup/code",
        component: VerificationWr,
        title: "VerificationWr",
    },
    {
        path: "/signup/nickname",
        component: NickNameWr,
        title: "NickNameWr",
    },
    {
        path: "/forgot/email",
        component: ForgotPasswordEmailWr,
        title: "ForgotPasswordEmailWr",
    },
    {
        path: "/forgot/code",
        component: ForgotPasswordWr,
        title: "ForgotPasswordWr",
    },
    {
        path: "/forgot/pass",
        component: CreatePasswordWr,
        title: "CreatePasswordWr",
    },
    // {
    //     path: "/forgot/pass",
    //     component: ForgotCreatePassword,
    //     title: "CreatePasswordWr",
    // },
    {
        path: "/forgot/confirm",
        component: ConfirmPasswordWr,
        title: "ConfirmPasswordWr",
    },
    {
        path: "/dashboard",
        component: Hoc(Dashboard),
        title: "Dashboard",
    },
    {
        path: "/registration",
        component: ViewWithIllustrationWr,
        title: "Registration",
    },
    {
        path: "/dashboard/:roomId",
        component: Hoc(Room),
        title: "Project",
    },
    {
        path: "/profile",
        component: Hoc(Profile),
        title: "Profile",
    },
    {
        path: "/spaces",
        component: Hoc(Spaces),
        title: "Spaces",
    },
    {
        path: "/expired-link",
        component: ExpiredPage,
        title: "Expired Link"
    },
    {
        path: "/guest/:roomId",
        component: Guest,
        title: "Guest"
    },
    {
        path: "/fastex",
        component: FastexComp,
        title: "FastexAuth"
    },
    {
        path: "/twitch",
        component: TwitchComp,
        title: "TwitchAuth"
    },
    {
        path: "/404",
        component: NotFoundPage,
        title: "404",
    },
    {
        path: "/maintenance",
        component: MaintenancePage,
        title: "Maintenance"
    },
    {
        path: "/set_new_password/:token",
        component: ForgotPassword,
        title: "Forgot Password"
    },
    {
        path: "/room/:roomId",
        component: RedirectContent,
        title: "Partners Redirect"
    },
    {
        path: "/dream-package/:roomId",
        component: DreamPackage,
        title: "Dream Package Redirect"
    },
    {
        path: "/invite-user-redirect/:roomId",
        component: InviteContent,
        title: " Invite Content"
    },
    {
        path: "/home_space",
        component: HomeSpace,
        title: "HomeSpace",
    },
    {
        path: "/connect_nft",
        component: BuyNft,
        title: "HomeSpace",
    },
];

export default routes;
