import React, {useRef} from "react";
import {observer} from "mobx-react-lite";
import {useWeb3Modal} from "@web3modal/wagmi/react";
import Web3 from "web3";

import {translationStore} from "../../../store/translationStore";
import {dashboardStore} from "../../../store/dashboardStore";
import {nftStore} from "../../../store/nftStore";

import useOnClickOutside from "../../../hooks/useOnClickOutside";

import {BAHANUT_RPC_URL} from "../../../constants/nft";

import Background from "../../Inventory/Categories/NFT/Background/Background";

import metamaskImg from "../../../assets/icons/wallets/metamask.svg";
// import walletConnectImg from "../../../assets/icons/wallets/walletConnect.svg";

import styles from "./walletPopup.module.scss";

const WalletPopup = observer(() => {
    const {translationData} = translationStore;
    const {setShowWalletPopup} = dashboardStore;
    const walletRef = useRef();
    const {open, close} = useWeb3Modal();
    const {setMetamaskAddress, setBalance} = nftStore;

    const fetchMetaMaskData = async () => {
        try {
            window.web3 = new Web3(window.ethereum);
            const accounts = await window.ethereum.request({
                method: "eth_requestAccounts",
            });
            try {
                await window.ethereum.request({
                    method: "wallet_switchEthereumChain",
                    params: [{ chainId: window.web3.utils.toHex(5165) }],
                });
            } catch (switchError) {
                    try {
                        await window.ethereum.request({
                                method: "wallet_addEthereumChain",
                                params: [
                                    {
                                        chainId: window.web3.utils.toHex(5165),
                                        chainName: "Bahamut",
                                        rpcUrls: [BAHANUT_RPC_URL],
                                        nativeCurrency: {
                                            name: "Fasttoken",
                                            decimals: 18,
                                            symbol: "FTN"
                                        }
                                    },
                                ],
                            });
                    } catch (e) {
                        console.warn("ERROR: ", e);
                    }
                // Handle other "switch" errors.
            }

            setMetamaskAddress(accounts?.[0])
            let weiBalance = await window.web3.eth.getBalance(accounts?.[0]);
            let ethBalance = window.web3.utils.fromWei(weiBalance, "ether");
            setBalance(ethBalance);
        } catch (e) {
            console.warn("ERROR: ", e);
        }
    };

    const handleMetamask = () => {
        fetchMetaMaskData();
        closePopup();
    };

    // const handleWalletConnect = () => {
    //     void open();
    //     closePopup();
    // };
    const closePopup = () => {
        setShowWalletPopup(false);
        void close();
    };

    useOnClickOutside(walletRef, closePopup);

    return (
        <Background>
            <div ref={walletRef} className={styles.walletContainer}>
                <div className={styles.walletHeader}>
                    <h1 className={styles.walletTitle}>{translationData?.["connect_wallet"] || "Connect wallet"}</h1>
                    <i className={styles.iconClose} onClick={closePopup}></i>
                </div>
                <div className={styles.walletsTypes}>
                    <button className={styles.wallet} onClick={() => handleMetamask()}>
                        <img className={styles.walletImg} alt="MetaMask" src={metamaskImg}/>
                        <span className={styles.walletName}>MetaMask</span>
                    </button>
                    {/*<button className={styles.wallet} onClick={() => handleWalletConnect()}>*/}
                    {/*    <img className={styles.walletImg} alt="WalletConnect" src={walletConnectImg}/>*/}
                    {/*    <span className={styles.walletName}>WalletConnect</span>*/}
                    {/*</button>*/}
                </div>
            </div>
        </Background>
    );
});

export default WalletPopup;