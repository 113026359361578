import styles from "./fadedBackground.module.css";

const FadedBackground = ({children, revertColor = false}) => {
    return (
        <div className={`${styles.fadedBackground} ${revertColor ? styles.revertColors : ""}`}>
            {children}
        </div>
    )
}

export default FadedBackground;