import React, {useRef} from 'react'
import {observer} from 'mobx-react-lite'
import {useLocation, useNavigate} from 'react-router-dom'

import {tr} from "../../../helpers/translations/tr";

import {roomStore} from '../../../store/roomStore'
import {unityStore} from '../../../store/unityStore'

import useOnClickOutside from '../../../hooks/useOnClickOutside'

import {cacheService} from '../../../services/cacheService'

import FadedBackground from "../PopupsWrappers/FadedBackground";
import PopupContainer from "../PopupsWrappers/PopupContainer";

import styles from "../popups.module.css";

const TicketUploadGuest = observer(() => {
    const {setShowGuestPopup} = roomStore
    const {setLoadUnity, setIsActiveMobile} = unityStore
    const ref = useRef()
    const navigate = useNavigate()
    const location = useLocation()
    const closePopup = () => {
        if (Number(cacheService.get('AlternateGuest')) === Number(location.pathname.split('/dashboard/')[1])) {
            navigate('/dashboard')
        }
        setShowGuestPopup(false)
    }
    useOnClickOutside(ref, closePopup)

    const pressToSignIn = () => {
        const alternateGuest = cacheService.get('AlternateGuest')
        const lang = cacheService.get('Language')
        cacheService.clear()
        cacheService.set('AlternateGuest', alternateGuest)
        cacheService.set('Language', lang)
        setLoadUnity(false)
        setIsActiveMobile(false)
        closePopup()
        navigate('/')
    }

    return (
        <FadedBackground>
            <PopupContainer revert={true} action={closePopup}>
                <h2 className={styles.popupTitle}>{tr("want_to_continue")}</h2>
                <p className={styles.popupDescription}>{(tr("please_sing_in_sign_up"))}</p>
                <div className={styles.popupButtonsContainer}>
                    <button onClick={pressToSignIn} className={styles.popupButton}>{tr("sign_in")}</button>
                    <button onClick={pressToSignIn}
                            className={`${styles.popupButton} ${styles.magenta}`}>{tr("sign_up")}</button>
                </div>
            </PopupContainer>
        </FadedBackground>
    )
})

export default TicketUploadGuest