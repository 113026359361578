import React, {useRef, useState} from "react";
import {observer} from "mobx-react-lite";
import {nftStore} from "../../../store/nftStore";
import spiner from "../../../assets/images/spiner.gif";
import {cacheService} from "../../../services/cacheService";
import {ReactComponent as Sale} from "../../../assets/icons/sale.svg";
import styles from "./index.module.scss";
import InfoContainer from "./InfoContainer/InfoContainer";
import { useNftData, useNftTransaction } from "../../../hooks/useNftAPI";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import { translationStore } from "../../../store/translationStore";


const CerebrumNftNew = observer(() => {
    const [tab, setTab]= useState("tab1");
    const ref = useRef();
    const {setSelectedCerebrumNft, clearNftData} = nftStore;

    
    const {owner, price, description, status, image, imageLoaded, setImageLoaded} = useNftData()
    
    const { buyTransaction, account, pending } = useNftTransaction()
    
    const {translationData} = translationStore;


    const closePopup = () => {
        setSelectedCerebrumNft(0);
        clearNftData()
    };

    useOnClickOutside(ref, () => closePopup());

    return (
        <div ref={ref} className={styles.buy_nft_inner_container}>
            <div className={styles.buy_nft_outer_container}>
                <i className={styles.icon_close} onClick={closePopup}/>
                <div className={`${styles.buy_nft_img_container} ${status === "2" ? styles.sold : ""}`}>
                    {!imageLoaded ? <span className={styles.loadImage}/> : <></>}
                    <img alt="nft" className={styles.buy_nft_img} src={image} onLoad={() => setImageLoaded(true)}/>
                    {(owner && owner === account) ? <h1>Is My NFT</h1> : <></>}
                </div>
                <div className={styles.buy_nft_desc_container}>
                    <div className={styles.nft_info}>
                        <div className={styles.scrol_content}>
                            <div className={styles.description}>
                                <p className={styles.description_title}>{translationData?.['description'] || 'Description'}</p>
                                <p>{description}</p>
                            </div>
                            <div className={styles.tabs_container}>
                                {/*<div className={`${styles.blockchain_btn} ${tab === 'tab1' ? styles.selected : ''}`}*/}
                                {/*    onClick={() => setTab('tab1')}>{translationData?.['blockchain'] || 'Blockchain'}</div>*/}
                                {/*<div className={`${styles.transaction_history} ${tab === 'tab2' ? styles.selected : ''}`}*/}
                                {/*    onClick={() => setTab('tab2')}>{translationData?.['transaction_history'] || 'Transaction history'}</div>*/}
                            </div>
                            <InfoContainer
                                tab={tab}
                            />
                        </div>
                    </div>
                    {Number(price) !== 0 && status === "1" ?
                        <div className={styles.buy_nft_discount}><Sale/>{price} FTN</div> : <></>}
                    <button
                        disabled={status !== "1" || pending || cacheService.isGuest}
                        className={styles.buy_nft_btn}
                        type="button"
                        onClick={buyTransaction}
                    >
                        {price === "" || pending ? <img alt={"load"} src={spiner} className={styles.spinner}/> :
                            status === "1" ? `${translationData?.["buy"]} ${price - 0.3 * price} FTN` :
                                status === "2" && owner === account ? "Purchased" :
                                    status === "2" ? translationData?.["sold"] || "SOLD" :
                                        translationData?.["out_of_stock"]
                        }
                        {pending && <span>Please Confirm In Your Wallet</span>}
                        {status === "1" ? <i className={styles.icon_discount}/> : <></>}
                        {cacheService.isGuest && <span>Guest Cannot Purchase</span>}
                    </button>
                </div>
            </div>
        </div>
    );
});

export default CerebrumNftNew;